<template>
  <svg
    id="Layer_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    :height="height"
    viewBox="0 0 512 512"
    :width="width"
    xml:space="preserve"
  >
    <path
      style="fill:#61B8CC;"
      d="M503.792,386.62c0,14.586-11.934,26.52-26.52,26.52H34.727c-14.586,0-26.52-11.934-26.52-26.52 V125.38c0-14.586,11.934-26.52,26.52-26.52h442.545c14.586,0,26.52,11.934,26.52,26.52V386.62z"
    />
    <rect
      x="8.208"
      y="145.496"
      style="fill:#333E48;"
      width="495.582"
      height="31.383"
    />
    <path
      style="fill:#FED771;"
      d="M462.189,267.934c0,5.835-4.773,10.609-10.608,10.609h-57.017c-5.833,0-10.608-4.774-10.608-10.609 v-34.476c0-5.834,4.775-10.607,10.608-10.607h57.017c5.835,0,10.608,4.773,10.608,10.607V267.934z"
    />
    <ellipse
      style="fill:#E5AB46;"
      cx="423.07"
      cy="250.697"
      rx="29.143"
      ry="20.377"
    />
    <rect
      x="47.818"
      y="222.408"
      style="fill:#99DAEA;"
      width="181.664"
      height="56.587"
    />
    <path
      style="fill:#1E252B;"
      d="M180.55,324.565c-7.056,0-11.884,4.78-11.884,11.019v0.091c0,6.146,4.508,10.108,11.292,10.108
c3.416,0,5.873-1.458,7.468-3.368c-0.183,5.871-3.005,10.016-7.696,10.016c-2.187,0-3.961-0.637-5.829-1.958
c-0.455-0.274-0.956-0.455-1.549-0.455c-1.458,0-2.641,1.093-2.641,2.55c0,1.046,0.5,1.821,1.274,2.321
c2.368,1.594,5.008,2.642,8.605,2.642c8.605,0,13.66-7.102,13.66-17.348v-0.092c0-6.284-1.503-9.789-3.825-12.156
C187.154,325.703,184.559,324.565,180.55,324.565z M187.244,335.356c0,3.096-2.413,5.736-6.465,5.736
c-3.962,0-6.511-2.366-6.511-5.69v-0.091c0-3.279,2.367-5.919,6.329-5.919c4.052,0,6.647,2.55,6.647,5.872v0.092H187.244z
 M58.699,324.701h-0.091c-0.82,0-1.64,0.183-2.733,0.501l-4.826,1.547c-1.139,0.318-1.867,1.229-1.867,2.413
c0,1.275,1.139,2.368,2.459,2.368c0.274,0,0.683-0.047,1.001-0.137l3.28-1.002v24.041c0,1.55,1.275,2.777,2.777,2.777
c1.549,0,2.777-1.228,2.777-2.777v-26.955C61.477,325.93,60.293,324.701,58.699,324.701z M144.396,340.456
c2.687-1.412,4.644-3.551,4.644-7.102v-0.092c0-4.917-4.689-8.604-11.064-8.604s-11.064,3.734-11.064,8.604v0.092
c0,3.551,1.959,5.691,4.644,7.102c-3.597,1.503-5.738,3.915-5.738,7.74v0.09c0,5.51,5.192,9.153,12.157,9.153
c6.967,0,12.157-3.552,12.157-9.243v-0.092C150.133,344.326,147.902,342.049,144.396,340.456z M132.284,333.763
c0-2.414,2.277-4.418,5.692-4.418c3.416,0,5.692,2.004,5.692,4.462v0.091c0,2.733-2.413,4.69-5.692,4.69s-5.692-1.958-5.692-4.734
C132.284,333.854,132.284,333.763,132.284,333.763z M144.669,347.968c0,2.642-2.504,4.781-6.693,4.781s-6.648-2.185-6.648-4.781
v-0.091c0-2.914,2.868-4.825,6.648-4.825s6.693,1.911,6.693,4.825V347.968z M477.273,90.652H34.727C15.578,90.652,0,106.23,0,125.38
V386.62c0,19.149,15.578,34.727,34.727,34.727h442.545c19.148,0,34.727-15.578,34.727-34.727V125.38
C512,106.23,496.421,90.652,477.273,90.652z M495.585,386.62c0,10.098-8.214,18.312-18.312,18.312H34.727
c-10.097,0-18.312-8.215-18.312-18.312V185.091h479.169V386.62z M495.585,168.676H16.415v-14.968h479.169V168.676z M495.585,137.293
H16.415V125.38c0-10.098,8.214-18.312,18.312-18.312h442.545c10.098,0,18.312,8.215,18.312,18.312V137.293z M108.332,324.565
c-7.056,0-11.884,4.78-11.884,11.019v0.091c0,6.146,4.508,10.108,11.292,10.108c3.416,0,5.873-1.458,7.468-3.368
c-0.183,5.871-3.005,10.016-7.696,10.016c-2.185,0-3.961-0.637-5.829-1.958c-0.455-0.274-0.956-0.455-1.549-0.455
c-1.458,0-2.641,1.093-2.641,2.55c0,1.046,0.501,1.821,1.274,2.321c2.368,1.594,5.008,2.642,8.605,2.642
c8.605,0,13.66-7.102,13.66-17.348v-0.092c0-6.284-1.501-9.789-3.825-12.156C114.934,325.703,112.34,324.565,108.332,324.565z
 M115.025,335.356c0,3.096-2.413,5.736-6.465,5.736c-3.962,0-6.511-2.366-6.511-5.69v-0.091c0-3.279,2.367-5.919,6.329-5.919
c4.052,0,6.647,2.55,6.647,5.872v0.092H115.025z M88.889,352.066H75.958l6.556-5.464c5.646-4.597,8.288-7.33,8.288-12.339v-0.09
c0-5.692-4.28-9.515-10.518-9.515c-5.009,0-7.968,1.913-10.563,5.144c-0.41,0.501-0.637,1.138-0.637,1.731
c0,1.411,1.137,2.549,2.55,2.549c0.911,0,1.549-0.409,1.958-0.866c1.958-2.366,3.734-3.55,6.284-3.55
c2.914,0,5.145,1.82,5.145,4.963c0,2.867-1.594,4.916-5.964,8.604l-9.789,8.332c-0.956,0.774-1.458,1.685-1.458,2.779
c0,1.639,1.276,2.64,3.005,2.64H88.89c1.366,0,2.458-1.092,2.458-2.458C91.346,353.159,90.254,352.066,88.889,352.066z
 M356.637,354.07c2.595,2.003,5.873,3.46,10.199,3.46c6.829,0,11.655-4.326,11.655-10.79v-0.092c0-6.739-4.872-10.017-11.109-10.017
c-2.141,0-3.551,0.363-5.055,0.912l0.501-7.468h11.975c1.366,0,2.504-1.092,2.504-2.458c0-1.365-1.138-2.504-2.504-2.504h-14.434
c-1.367,0-2.46,1.047-2.551,2.504l-0.683,11.473c-0.046,1.048,0.272,1.867,1.092,2.458c1.092,0.775,1.776,1.093,2.642,1.093
c0.865,0,2.367-1.093,5.463-1.093c3.871,0,6.602,2.005,6.602,5.28v0.092c0,3.371-2.595,5.51-6.192,5.51
c-2.504,0-4.735-0.911-6.921-2.688c-0.455-0.362-0.956-0.545-1.639-0.545c-1.457,0-2.687,1.23-2.687,2.686
C355.498,352.795,355.954,353.523,356.637,354.07z M257.644,356.983h18.076c1.366,0,2.459-1.092,2.459-2.458
c0-1.366-1.093-2.459-2.459-2.459h-12.931l6.556-5.464c5.646-4.597,8.288-7.33,8.288-12.339v-0.09c0-5.692-4.28-9.515-10.518-9.515
c-5.009,0-7.968,1.913-10.563,5.143c-0.41,0.501-0.637,1.138-0.637,1.731c0,1.411,1.137,2.549,2.55,2.549
c0.911,0,1.547-0.409,1.958-0.866c1.959-2.366,3.734-3.55,6.284-3.55c2.914,0,5.145,1.82,5.145,4.963
c0,2.867-1.593,4.916-5.964,8.604l-9.789,8.332c-0.956,0.774-1.457,1.685-1.457,2.779
C254.639,355.983,255.914,356.983,257.644,356.983z M394.564,286.75h57.017c10.375,0,18.815-8.441,18.815-18.816v-34.476
c0-10.375-8.441-18.814-18.815-18.814h-57.017c-10.375,0-18.815,8.441-18.815,18.814v34.476
C375.749,278.309,384.189,286.75,394.564,286.75z M392.164,233.458c0-1.301,1.099-2.399,2.4-2.399h57.017
c1.301,0,2.4,1.099,2.4,2.399v34.476c0,1.301-1.099,2.401-2.4,2.401h-57.017c-1.301,0-2.4-1.1-2.4-2.401V233.458z M338.467,357.439
c6.967,0,12.157-3.552,12.157-9.243v-0.092c0-3.779-2.23-6.055-5.737-7.649c2.687-1.412,4.644-3.551,4.644-7.102v-0.092
c0-4.917-4.69-8.604-11.064-8.604c-6.375,0-11.064,3.734-11.064,8.604v0.092c0,3.551,1.958,5.691,4.643,7.102
c-3.597,1.503-5.737,3.915-5.737,7.74v0.09C326.31,353.796,331.501,357.439,338.467,357.439z M332.775,333.763
c0-2.414,2.277-4.418,5.692-4.418c3.415,0,5.692,2.004,5.692,4.462v0.091c0,2.733-2.413,4.69-5.692,4.69s-5.692-1.958-5.692-4.734
V333.763z M331.819,347.877c0-2.914,2.869-4.825,6.648-4.825s6.693,1.911,6.693,4.825v0.091c0,2.642-2.504,4.781-6.693,4.781
s-6.648-2.185-6.648-4.781V347.877z M308.232,345.783c3.415,0,5.873-1.458,7.467-3.368c-0.183,5.871-3.005,10.016-7.694,10.016
c-2.187,0-3.96-0.637-5.829-1.958c-0.455-0.274-0.956-0.455-1.549-0.455c-1.457,0-2.641,1.093-2.641,2.55
c0,1.046,0.5,1.821,1.275,2.321c2.368,1.594,5.008,2.642,8.605,2.642c8.606,0,13.66-7.102,13.66-17.348v-0.092
c0-6.284-1.503-9.789-3.825-12.156c-2.276-2.231-4.872-3.37-8.879-3.37c-7.056,0-11.884,4.78-11.884,11.019v0.091
C296.939,341.822,301.448,345.783,308.232,345.783z M302.541,335.31c0-3.279,2.367-5.919,6.329-5.919
c4.052,0,6.647,2.55,6.647,5.872v0.092c0,3.096-2.414,5.736-6.465,5.736c-3.962,0-6.511-2.366-6.511-5.69v-0.091H302.541z
 M407.771,345.236h-2.459v-17.894c0-1.503-1.229-2.686-2.732-2.686c-1.639,0-2.414,0.637-3.417,1.821l-14.98,17.758
c-0.82,0.955-1.229,1.866-1.229,2.958c0,1.547,1.229,2.641,2.732,2.641h14.251v4.735c0,1.503,1.185,2.641,2.688,2.641
s2.686-1.138,2.686-2.641v-4.735h2.459c1.321,0,2.322-1.001,2.322-2.276C410.093,346.283,409.092,345.236,407.771,345.236z
 M399.939,345.236h-10.472l10.472-12.611V345.236z M228.044,327.57c0,1.321,1.093,2.413,2.459,2.413h11.565l-7.468,7.968
c-0.865,0.866-1.184,1.549-1.184,2.275c0,1.321,1.092,2.414,2.414,2.414h1.138c4.463,0,7.331,1.776,7.331,4.917v0.091
c0,2.914-2.368,4.827-5.51,4.827c-2.959,0-5.235-1.047-7.239-3.052c-0.455-0.408-1.047-0.727-1.867-0.727
c-1.457,0-2.687,1.229-2.687,2.684c0,0.822,0.409,1.594,0.911,2.005c2.596,2.504,6.102,4.143,10.792,4.143
c6.647,0,11.155-4.235,11.155-10.108v-0.091c0-5.875-4.554-8.334-9.38-8.925l7.513-7.785c0.956-0.956,1.593-1.73,1.593-3.051
c0-1.503-1.185-2.458-2.777-2.458h-16.301C229.138,325.112,228.044,326.204,228.044,327.57z M216.614,340.456
c2.687-1.412,4.644-3.551,4.644-7.102v-0.092c0-4.917-4.689-8.604-11.064-8.604s-11.064,3.734-11.064,8.604v0.092
c0,3.551,1.959,5.691,4.644,7.102c-3.597,1.503-5.738,3.915-5.738,7.74v0.09c0,5.51,5.192,9.153,12.157,9.153
c6.967,0,12.157-3.552,12.157-9.243v-0.092C222.352,344.326,220.12,342.049,216.614,340.456z M204.504,333.763
c0-2.414,2.276-4.418,5.691-4.418c3.416,0,5.692,2.004,5.692,4.462v0.091c0,2.733-2.413,4.69-5.692,4.69s-5.691-1.958-5.691-4.734
V333.763z M216.887,347.968c0,2.642-2.504,4.781-6.693,4.781s-6.647-2.185-6.647-4.781v-0.091c0-2.914,2.868-4.825,6.647-4.825
c3.78,0,6.693,1.911,6.693,4.825V347.968z M47.818,287.199h181.66c4.533,0,8.208-3.674,8.208-8.208v-56.587
c0-4.534-3.675-8.208-8.208-8.208H47.818c-4.533,0-8.208,3.674-8.208,8.208v56.587C39.61,283.524,43.285,287.199,47.818,287.199z
M56.026,230.611H221.27v40.172H56.026V230.611z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default() {
        return ''
      },
    },
    height: {
      type: Number,
      default() {
        return ''
      },
    },
    width: {
      type: Number,
      default() {
        return ''
      },
    },
  },
}
</script>
