<template>
  <svg
    :height="height"
    viewBox="0 0 43 26"
    :width="width"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <linearGradient id="a">
      <stop
        offset="0"
        stop-color="#ed1c24"
      />
      <stop
        offset=".26"
        stop-color="#ea1b23"
      />
      <stop
        offset=".48"
        stop-color="#e01920"
      />
      <stop
        offset=".67"
        stop-color="#cf161c"
      />
      <stop
        offset=".85"
        stop-color="#b71016"
      />
      <stop
        offset="1"
        stop-color="#9e0b0f"
      />
    </linearGradient>
    <linearGradient
      id="b"
      x1="49.988%"
      x2="49.988%"
      xlink:href="#a"
      y1="-34.226%"
      y2="137.575%"
    />
    <linearGradient
      id="c"
      x1="5208%"
      x2="5208%"
      xlink:href="#a"
      y1="624%"
      y2="5754%"
    />
    <linearGradient
      id="d"
      x1="3112%"
      x2="3112%"
      xlink:href="#a"
      y1="624%"
      y2="5754%"
    />
    <g fill="none">
      <path
        d="m30.168.19h-16.668c-4.466 0-8.592 2.364-10.825 6.2a12.317 12.317 0 0 0 0 12.402c2.233 3.836 6.36 6.2 10.825 6.2h16.668c6.904 0 12.5-5.552 12.5-12.4 0-6.85-5.596-12.402-12.5-12.402z"
        fill="#fff"
        stroke="#333"
        stroke-width=".45"
      />
      <path
        d="m32.08 11.84 5.54 6.54a10.107 10.107 0 0 0 -.196-11.848l-5.344 5.31z"
        fill="url(#b)"
        transform="translate(1)"
      />
      <path
        d="m16.376 5.98c4.14 0 7.256 1.044 7.576 4.504l4.356-4.504h8.676a10.298 10.298 0 0 0 -7.816-3.6h-16.668a10.308 10.308 0 0 0 -7.816 3.573l11.692.028z"
        fill="url(#c)"
        transform="translate(1)"
      />
      <path
        d="m9.556 10.34-1.476 4.034h5.96c1.764 0 2.296-.722 2.692-2.12.396-1.396-.592-1.912-1.796-1.912h-5.38z"
        fill="url(#d)"
        transform="translate(1)"
      />
      <path
        d="m28.344 18.933-4.604-5.778c-.852 3.936-3.64 5.774-8.868 5.774h-10.4a10.302 10.302 0 0 0 8.044 3.855h16.652a10.292 10.292 0 0 0 8.032-3.853h-8.856z"
        fill="url(#c)"
        transform="translate(1)"
      />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default() {
        return ''
      },
    },
    height: {
      type: Number,
      default() {
        return ''
      },
    },
    width: {
      type: Number,
      default() {
        return ''
      },
    },
  },
}
</script>
